// Redux
import { createReducer } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import { addCustomScript, resetCustomScript } from "./actions";
import { updateCustomScriptSideEffect } from "../study/actions/side-effects";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  // Reset custom script
  builder.addCase(resetCustomScript, () => initialState);
  // set custom script
  builder.addCase(addCustomScript, (state, action) => ({
    ...state,
    data: action.payload,
  }));
  builder.addCase(updateCustomScriptSideEffect, (state, action) => ({
    ...state,
    data: action.payload || undefined,
  }));
});
