import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { RagFile, RagFilesStats, UploadRagFile } from "./types";
import { RagFileSchema, RagFilesStatsSchema, UploadRagFileSchema } from "./schemas";
import { initialState } from "./initial-state";
import { customBaseQuery } from "store/utils/custom-base-query";
import { RcFile } from "antd/es/upload";
import { nanoid } from "@reduxjs/toolkit";
import { z } from "zod";
import { RootState } from "store"; // Import RootState
import { parseError, isCustomError } from "store/utils/parse-error";
import { TransportFailure } from "@/logic/internals/transports/transported-data/transport-failures";

// Create the API slice
export const ragApi = createApi({
  reducerPath: "ragApi",
  baseQuery: customBaseQuery(fetchBaseQuery()),
  tagTypes: ["RagFiles"],
  endpoints: (builder) => ({
    getRagFiles: builder.query<RagFile[], { projectId: string }>({
      query: ({ projectId }) => `/upload/projects/${projectId}`,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;

          dispatch(ragSlice.actions.setRagFiles(response.data));
        } catch (error) {
          const parsedError = parseError(error);
          if (parsedError && isCustomError(parsedError)) {
            dispatch(ragSlice.actions.setError({ error: parsedError.error }));
          } else {
            dispatch(ragSlice.actions.setError({ error: TransportFailure.UnexpectedResponse }));
          }
        }
      },
      transformResponse: (response: RagFile[]) => {
        return response.sort((a, b) => b.uploadedAt.localeCompare(a.uploadedAt));
      },
      providesTags: ["RagFiles"],
      extraOptions: {
        dataSchema: z.array(RagFileSchema),
      },
    }),
    getRagFilesStats: builder.query<RagFilesStats, { projectId: string }>({
      query: ({ projectId }) => `/upload/${projectId}/stats`,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;

          dispatch(ragSlice.actions.setRagFilesStats(response.data));
        } catch (error) {
          const parsedError = parseError(error);
          if (parsedError && isCustomError(parsedError)) {
            dispatch(ragSlice.actions.setError({ error: parsedError.error }));
          } else {
            dispatch(ragSlice.actions.setError({ error: TransportFailure.UnexpectedResponse }));
          }
        }
      },
      providesTags: ["RagFiles"],
      extraOptions: {
        dataSchema: RagFilesStatsSchema,
      },
    }),
    uploadRagFile: builder.mutation<
      UploadRagFile,
      {
        projectId: string;
        file: RcFile;
        userEmail: string;
        onSuccess: () => void;
        onError: () => void;
      }
    >({
      query: ({ projectId, file }) => ({
        url: "/upload",
        method: "POST",
        body: { project_id: projectId, filename: file.name },
      }),
      async onQueryStarted(
        { projectId, file, userEmail, onSuccess, onError },
        { dispatch, queryFulfilled }
      ) {
        const optimisticRagFileId = nanoid();

        dispatch(
          ragSlice.actions.addOptimisticRagFile({
            projectId,
            file,
            userEmail,
            temporaryId: optimisticRagFileId,
          })
        );

        try {
          const { data: uploadRagFile } = await queryFulfilled;

          const { url, fields } = uploadRagFile.presigned;

          const formData = new FormData();

          Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value);
          });

          formData.append("file", file);

          const upload = await fetch(url, {
            method: "POST",
            body: formData,
          });

          if (upload.ok) {
            dispatch(
              ragApi.endpoints.markUploadAsCompleted.initiate({
                fileId: uploadRagFile.id,
                temporaryId: optimisticRagFileId,
                onSuccess,
                onError,
              })
            );
          } else {
            onError();
            dispatch(
              ragSlice.actions.removeOptimisticRagFile({ temporaryId: optimisticRagFileId })
            );
            dispatch(ragSlice.actions.setError({ error: TransportFailure.UnexpectedResponse }));
          }
        } catch (error) {
          onError();
          dispatch(ragSlice.actions.removeOptimisticRagFile({ temporaryId: optimisticRagFileId }));

          const parsedError = parseError(error);
          if (parsedError && isCustomError(parsedError)) {
            dispatch(ragSlice.actions.setError({ error: parsedError.error }));
          } else {
            dispatch(ragSlice.actions.setError({ error: TransportFailure.UnexpectedResponse }));
          }
        }
      },
      extraOptions: {
        dataSchema: UploadRagFileSchema,
      },
    }),
    markUploadAsCompleted: builder.mutation<
      RagFile,
      { fileId: string; temporaryId: string; onSuccess: () => void; onError: () => void }
    >({
      query: ({ fileId }) => ({
        url: `/upload/${fileId}/status`,
        method: "POST",
      }),
      async onQueryStarted(
        { temporaryId, onSuccess, onError },
        { dispatch, getState, queryFulfilled }
      ) {
        try {
          const { data: completedRagFile } = await queryFulfilled;

          const state = getState() as RootState;
          const currentRagFiles = state.rag.data?.ragFiles || [];

          dispatch(
            ragSlice.actions.setRagFiles(
              currentRagFiles.map((ragFile) =>
                ragFile.id === temporaryId
                  ? { ...completedRagFile, processingPercentage: 0 }
                  : ragFile
              )
            )
          );
          onSuccess();
        } catch (error) {
          onError();
          dispatch(ragSlice.actions.removeOptimisticRagFile({ temporaryId }));

          const parsedError = parseError(error);
          if (parsedError && isCustomError(parsedError)) {
            dispatch(ragSlice.actions.setError({ error: parsedError.error }));
          } else {
            dispatch(ragSlice.actions.setError({ error: TransportFailure.UnexpectedResponse }));
          }
        }
      },
      extraOptions: {
        dataSchema: RagFileSchema,
      },
    }),
    deleteRagFile: builder.mutation<
      void,
      { fileId: string; onSuccess: () => void; onError: () => void }
    >({
      query: ({ fileId }) => ({
        url: `/upload/${fileId}`,
        method: "DELETE",
      }),
      async onQueryStarted({ fileId, onSuccess, onError }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          onSuccess();
          dispatch(ragSlice.actions.removeRagFile({ fileId }));
        } catch (error) {
          onError();

          const parsedError = parseError(error);
          if (parsedError && isCustomError(parsedError)) {
            dispatch(ragSlice.actions.setError({ error: parsedError.error }));
          } else {
            dispatch(ragSlice.actions.setError({ error: TransportFailure.UnexpectedResponse }));
          }
        }
      },
    }),
  }),
});

// Create the regular slice
export const ragSlice = createSlice({
  name: "rag",
  initialState,
  reducers: {
    resetRag: () => initialState,
    setRagFiles: (state, action: PayloadAction<RagFile[]>) => {
      return {
        ...state,
        data: {
          ragFiles: action.payload,
          selectedRagFiles: state.data?.selectedRagFiles || [],
        },
      };
    },
    setRagFilesStats: (state, action: PayloadAction<RagFilesStats>) => {
      if (!state.data?.ragFiles) return state;

      state.data.ragFiles = state.data.ragFiles.map((ragFile) => {
        const file = Object.entries(action.payload).find(([id]) => id == ragFile.id);

        if (!file) return ragFile;

        const total = file[1].total;
        const current = file[1].current;

        const processingPercentage = total === 0 ? 0 : Math.round((current / total) * 100);

        return {
          ...ragFile,
          processingPercentage,
        };
      });
    },
    setSelectedRagFiles: (state, action: PayloadAction<string[]>) => {
      if (!state.data) return state;

      state.data.selectedRagFiles =
        state.data.ragFiles?.filter((file) => action.payload.includes(file.id)) || [];
    },
    resetSelectedRagFiles: (state) => {
      if (!state.data) return state;

      state.data.selectedRagFiles = [];
    },
    addOptimisticRagFile: (
      state,
      action: PayloadAction<{
        projectId: string;
        file: RcFile;
        userEmail: string;
        temporaryId: string;
      }>
    ) => {
      const { projectId, file, userEmail, temporaryId } = action.payload;
      const optimisticFile: RagFile = {
        id: temporaryId,
        filename: file.name,
        projectId,
        uploadedBy: userEmail,
        uploadedAt: new Date().toISOString(),
        status: "uploading",
      };
      if (!state.data) return state;

      state.data.ragFiles = [optimisticFile, ...(state.data.ragFiles || [])];
    },
    removeOptimisticRagFile: (state, action: PayloadAction<{ temporaryId: string }>) => {
      if (!state.data) return state;

      state.data.ragFiles = state.data.ragFiles.filter(
        (file) => file.id !== action.payload.temporaryId
      );
    },
    removeRagFile: (state, action: PayloadAction<{ fileId: string }>) => {
      if (!state.data) return state;

      state.data.ragFiles = state.data.ragFiles.filter((file) => file.id !== action.payload.fileId);
      state.data.selectedRagFiles = state.data.selectedRagFiles.filter(
        (file) => file.id !== action.payload.fileId
      );
    },
    setError: (state, action: PayloadAction<{ error: TransportFailure }>) => {
      state.error = action.payload.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          ragApi.endpoints.getRagFiles.matchPending,
          ragApi.endpoints.uploadRagFile.matchPending,
          ragApi.endpoints.deleteRagFile.matchPending,
          ragApi.endpoints.markUploadAsCompleted.matchPending
        ),
        (state) => {
          state.loading += 1;
        }
      )
      .addMatcher(
        isAnyOf(
          ragApi.endpoints.getRagFiles.matchFulfilled,
          ragApi.endpoints.getRagFiles.matchRejected,
          ragApi.endpoints.uploadRagFile.matchFulfilled,
          ragApi.endpoints.uploadRagFile.matchRejected,
          ragApi.endpoints.deleteRagFile.matchFulfilled,
          ragApi.endpoints.deleteRagFile.matchRejected,
          ragApi.endpoints.markUploadAsCompleted.matchFulfilled,
          ragApi.endpoints.markUploadAsCompleted.matchRejected
        ),
        (state) => {
          state.loading -= 1;
        }
      );
  },
});

// Export actions
export const { resetRag, setSelectedRagFiles, resetSelectedRagFiles } = ragSlice.actions;

// Combine the reducers
export const ragReducer = {
  [ragApi.reducerPath]: ragApi.reducer,
  rag: ragSlice.reducer,
};
