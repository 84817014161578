// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import { generateSyntheticUsers, updateSyntheticUsers, resetSyntheticUsers } from "./actions";
import { updateSyntheticUsersSideEffect } from "../study/actions/side-effects";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder.addCase(resetSyntheticUsers, () => initialState);
  builder.addCase(updateSyntheticUsersSideEffect, (state, action) => ({
    ...state,
    data: action.payload.syntheticUsers ? action.payload.syntheticUsers : undefined,
  }));
  builder
    // Loading start
    .addMatcher(isAnyOf(generateSyntheticUsers.pending, updateSyntheticUsers.pending), (state) => ({
      ...state,
      loading: true,
    }))
    // Generate + Update Synthetic User fulfilled
    .addMatcher(
      isAnyOf(generateSyntheticUsers.fulfilled, updateSyntheticUsers.fulfilled),
      (state, action) => ({
        ...state,
        ...action.payload,
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(
        generateSyntheticUsers.fulfilled,
        generateSyntheticUsers.rejected,
        updateSyntheticUsers.fulfilled,
        updateSyntheticUsers.rejected
      ),
      (state) => ({
        ...state,
        loading: false,
      })
    );
});
