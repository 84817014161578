// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState, store } from "store";
import { StateProps, UserInterviewMessage, UserInterviewState } from "./types";
// Schemas
import {
  DoneUserInterviewSchema,
  InterviewAnnotationSchema,
  UserInterviewInAnyPhaseSchema,
} from "./schemas";
// Initial State
import { initialState } from "./initial-state";
// Main Api
import { getMainApi } from "store/utils/main-api";
// Zod
import { getAnalytics } from "logic/analytics/analytics";
import { WasClosed, WebSocketConnectionConfigKeys } from "store/middleware/websockets/types";
import { z } from "zod";
import { getHistory } from "../history/actions";
import { handleStudyType } from "store/utils/handle-study-stategy";
import { StudyStrategyCodes } from "store/utils/get-study-strategy-code";

let BATCH_SIZE = 5;

export const GENERATE_USER_INTERVIEWS_TYPE = "user-interviews/generate";

/**
 * Generate User Interviews
 * @example await/void dispatch(generateUserInterviews({ projectId, interviews, studyStrategy, language, ongoingWalkthrough, onSuccess }));
 */
export const generateUserInterviews = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    interviews: number;
    studyStrategy: StudyStrategyCodes;
    language?: string;
    ongoingWalkthrough?: boolean;
    onSuccess?: (studyId: string) => void;
  },
  { state: RootState }
>(
  GENERATE_USER_INTERVIEWS_TYPE,
  async (
    { projectId, interviews, studyStrategy, language, ongoingWalkthrough, onSuccess },
    { getState, dispatch }
  ) => {
    let data: StateProps["data"] = initialState.data;
    let error: StateProps["error"] = initialState.error;
    const { audiences, problems, solutions, customScript, syntheticUsers, researchGoal, rag } =
      getState();

    const body = {
      syntheticUsersIds: audiences.data.selectedAudiences?.map((audience) => audience.id),
      problemsIds: problems.data?.selectedProblems?.map((problem) => problem.id) || [],
      solutionId: solutions.data.selectedSolutions && solutions.data.selectedSolutions[0]?.id,
      customScript: customScript.data?.customScript
        ?.split("\n")
        .filter((line) => line.length)
        .join("\n"),
      generatedSyntheticUsersIds: syntheticUsers.data?.map((u) => u.id),
      researchGoalId: researchGoal.data?.id,
      studyStrategy: handleStudyType(studyStrategy),
      language: language,
      ongoingWalkthrough,
      uploadedFiles: rag?.data?.selectedRagFiles.map((file) => file.id),
    };
    const analytics = getAnalytics();
    const mainApi = getMainApi();

    analytics.track("workspace:run-interviews", {
      project_id: projectId,
      audiences: getState().audiences.data?.selectedAudiencesIds ?? [],
      problems: getState().problems.data?.selectedProblemsIds ?? [],
      solution: getState().solutions.data?.selectedSolutionId ?? "",
      research_goal: getState().researchGoal.data?.description ?? "",
      custom_script: getState().customScript.data?.customScript ?? "",
      study_strategy: getState().study.data?.studyStrategy ?? "",
      generated_synthetic_users: getState().syntheticUsers.data?.map((u) => u.id) ?? [],
    });

    const result = await mainApi.fetch({
      schema: z.object({
        status: z.union([
          z.literal(200),
          z.literal(403),
          z.literal(422),
          z.literal(500),
          z.literal(502),
        ]),
        body: z.array(UserInterviewInAnyPhaseSchema),
      }),
      // TODO: is quantity needed?
      path: `/userInterviews/generateAfterPersonaSculptor/${projectId}?quantity=${interviews}`,
      method: "POST",
      body,
    });

    if (result.failure) {
      error = result.failure;
    } else {
      // Set Initial User Interview Data
      data = result.response.body.reduce((acc, userInterview) => {
        const {
          userInterviewId,
          projectId,
          syntheticUserId,
          createdAt,
          generatedSyntheticUserPersonalityDetails,
        } = userInterview;

        return {
          ...acc,
          [userInterviewId]: {
            loading: true,
            firstAppearedAt: new Date(createdAt),
            data: {
              userInterviewId,
              projectId,
              syntheticUserId,
              createdAt,
              generatedSyntheticUserPersonalityDetails,
              userInterviewWithTopics: [],
              userInterviewWithTypes: [],
              topics: [],
            },
          },
        };
      }, {});

      const studyId = result.response.body[0] && result.response.body[0].studyId;

      if (studyId && onSuccess) {
        onSuccess(studyId);
      }

      dispatch(getHistory({ projectId }));

      if (audiences.data.selectedAudiences) {
        // dynamic interviews don't have stream so the batch size should be 1
        // otherwise it should be the total number interviews (if more than 5 - 5 is the default batch size)
        // to make sure we don't run into maximum update depth exceeded error
        const numberOfInterviews = audiences.data.selectedAudiences.length * interviews;
        BATCH_SIZE = researchGoal.data ? 1 : numberOfInterviews < 5 ? 5 : numberOfInterviews;
      }

      let delay = 0;
      result.response.body.forEach((userInterview) => {
        setTimeout(() => {
          dispatch(connectUserInterviewWS(userInterview.userInterviewId));
          delay += 10000;
        }, delay);
      });
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      data,
      error,
    };
  }
);

/**
 * Regenerate User Interview
 * @example await/void dispatch(regenerateUserInterview({ userInterviewId }));
 */
export const regenerateUserInterview = createAsyncThunk<
  Partial<StateProps>,
  {
    userInterviewId: string;
  },
  { state: RootState }
>("user-interviews/regenerate", async ({ userInterviewId }, { dispatch, getState }) => {
  let data: StateProps["data"] = getState().userInterviews.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.union([
        z.literal(200),
        z.literal(403),
        z.literal(422),
        z.literal(500),
        z.literal(502),
      ]),
      body: UserInterviewInAnyPhaseSchema,
    }),
    path: `/userInterviews/regenerate/${userInterviewId}`,
    method: "POST",
  });

  if (result.failure) {
    error = result.failure;
  } else {
    // Set Initial User Interview Data
    const { userInterviewId } = result.response.body;
    const prevInterview = data && data[userInterviewId];

    if (prevInterview) {
      data = {
        ...data,
        [userInterviewId]: {
          ...prevInterview,
          loading: true,
          error: undefined,
          data: {
            ...prevInterview.data,
            userInterview: "",
            userInterviewWithTopics: [],
            userInterviewWithTypes: [],
            topics: [],
          },
        },
      };
    }

    dispatch(connectUserInterviewWS(userInterviewId));
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Fetch user interview after streaming
 * @example await/void dispatch(getUserInterviewAfterStreaming({ interviewId }));
 */
export const getUserInterviewAfterStreaming = createAsyncThunk<
  { data: StateProps["data"] },
  { interviewId: string },
  { state: RootState }
>("user-interviews/fetch-after-streaming", async ({ interviewId }, { getState }) => {
  let data: StateProps["data"] = getState().userInterviews.data || initialState.data;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.literal(200),
      body: DoneUserInterviewSchema,
    }),
    path: `/userInterviews/${interviewId}`,
    method: "GET",
  });

  if (result.failure) {
    if (data && data[interviewId] && data[interviewId] !== undefined) {
      const prevInterview = data[interviewId] as UserInterviewState;
      data = {
        [interviewId]: {
          ...prevInterview,
          loading: false,
          error: result.failure,
        },
      };
    }
  } else {
    const {
      userInterview,
      userInterviewWithTopics,
      userInterviewWithTypes,
      topics,
      userInterviewId,
      projectId,
      studyId,
      syntheticUserId,
      createdAt,
      helpful,
      generatedSyntheticUserDescription,
      generatedSyntheticUserPersonalityDetails,
    } = result.response.body;

    if (data && data[interviewId] && data[interviewId] !== undefined) {
      const prevInterview = data[interviewId] as UserInterviewState;
      data = {
        [interviewId]: {
          ...prevInterview,
          loading: false,
          data: {
            userInterview,
            userInterviewWithTopics,
            userInterviewWithTypes,
            topics,
            userInterviewId,
            projectId,
            studyId,
            syntheticUserId,
            createdAt,
            helpful,
            generatedSyntheticUserDescription,
            generatedSyntheticUserPersonalityDetails,
            annotations: [],
          },
        },
      };
    }
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    data,
  };
});

/**
 * Give User Interview Feedback
 * @example await/void dispatch(giveUserInterviewFeedback({ interviewId, helpful }));
 */
export const giveUserInterviewFeedback = createAsyncThunk<
  Partial<StateProps>,
  { interviewId: string; helpful: boolean },
  { state: RootState }
>("user-interviews/give-feedback", async ({ interviewId, helpful }, { getState }) => {
  let data: StateProps["data"] = getState().userInterviews.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.literal(200),
    }),
    method: "POST",
    path: `/userInterviews/feedback/${interviewId}`,
    body: {
      helpful,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    if (data && data[interviewId] && data[interviewId] !== undefined) {
      const prevInterview = data[interviewId] as UserInterviewState;
      data = {
        ...data,
        [interviewId]: {
          ...prevInterview,
          loading: false,
          data: {
            ...prevInterview.data,
            helpful,
          },
        },
      };
    }
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

export const CONNECT_USER_INTERVIEWS_WS_ACTION_TYPE = "user-interviews/connect-ws";

/**
 * Connect to User Interviews websocket
 * @example await/void dispatch(connectUserInterviewWS(userInterviewId));
 */
export const connectUserInterviewWS = createAction(
  CONNECT_USER_INTERVIEWS_WS_ACTION_TYPE,
  function prepare(userInterviewId: string) {
    return {
      payload: {
        key: `${WebSocketConnectionConfigKeys.UserInterviews}-${userInterviewId}`,
        resourceId: userInterviewId,
      },
    };
  }
);

/**
 * Stream User Interviews
 * @example dispatch(streamUserInterviews({ userInterviewId, messages }));
 */
export const streamUserInterviews = createAction<{
  userInterviewId: string;
  messages: UserInterviewMessage[];
}>("user-interviews/stream");

export const DISCONNECT_USER_INTERVIEWS_STREAMING_ACTION_TYPE = "user-interviews/disconnect-ws";

/**
 * Disconnect User Interviews websocket
 * @example await/void dispatch(disconnectUserInterviewsWS(userInterviewId, wasClosed));
 */
export const disconnectUserInterviewsWS = createAction(
  DISCONNECT_USER_INTERVIEWS_STREAMING_ACTION_TYPE,
  function prepare(userInterviewId: string, wasClosed?: WasClosed) {
    return {
      payload: {
        key: `${WebSocketConnectionConfigKeys.UserInterviews}-${userInterviewId}`,
        resourceId: userInterviewId,
        wasClosed,
      },
    };
  }
);

/**
 * Reset User Interviews
 * @example dispatch(resetUserInterviews());
 */
export const resetUserInterviews = createAction("user-interviews/reset");

type BatchedMessage = {
  id: string;
  message: UserInterviewMessage;
};

let batchedMessages: BatchedMessage[] = [];

export const handleUserInterviewMessage = (
  userInterviewId: string,
  message: UserInterviewMessage
) => {
  const newMessage = {
    id: userInterviewId,
    message,
  };
  const currMessages = [...batchedMessages, newMessage];

  const currentIdMessage = currMessages.filter((m) => m.id === userInterviewId);

  // Check if we have reached the batch size
  if (currentIdMessage.length >= BATCH_SIZE) {
    const messages = currentIdMessage.map((m) => m.message);
    // Dispatch the batched messages

    store.dispatch(streamUserInterviews({ userInterviewId, messages }));

    // Clear the batched messages array
    batchedMessages = currMessages.filter((m) => m.id !== userInterviewId);
  } else {
    batchedMessages = currMessages;
  }
};

/**
 * Add User Interview Annotation
 * @example await/void dispatch(addUserInterviewAnnotation({ conversationId, userInterviewId, annotation, startIndex, endIndex, label }));
 */
export const addUserInterviewAnnotation = createAsyncThunk<
  Partial<StateProps>,
  {
    conversationId: string;
    userInterviewId: string;
    annotation: string;
    startIndex: number;
    endIndex: number;
    label: string;
  },
  { state: RootState }
>(
  "user-interviews/add-annotation",
  async (
    { conversationId, userInterviewId, annotation, startIndex, endIndex, label },
    { getState }
  ) => {
    let data: StateProps["data"] = getState().userInterviews.data || initialState.data;
    let error: StateProps["error"] = initialState.error;

    const mainApi = getMainApi();

    const result = await mainApi.fetch({
      schema: z.object({
        status: z.literal(200),
        body: InterviewAnnotationSchema,
      }),
      method: "POST",
      path: `/conversations/annotations`,
      body: {
        conversation_id: conversationId,
        user_interview_id: userInterviewId,
        annotation,
        start_index: startIndex,
        end_index: endIndex,
        label,
      },
    });

    if (result.failure) {
      error = result.failure;
    } else {
      if (data && data[userInterviewId] && data[userInterviewId] !== undefined) {
        const prevInterview = data[userInterviewId] as UserInterviewState;
        data = {
          ...data,
          [userInterviewId]: {
            ...prevInterview,
            data: {
              ...prevInterview.data,
              annotations: [...(prevInterview.data.annotations || []), result.response.body],
            },
          },
        };
      }
    }
    // The value we return becomes the `fulfilled` action payload
    return {
      data,
      error,
    };
  }
);

/**
 * Update User Interview Annotation Label
 * @example await/void dispatch(updateUserInterviewAnnotationLabel({ annotationId, label, userInterviewId }));
 */
export const updateUserInterviewAnnotationLabel = createAsyncThunk<
  Partial<StateProps>,
  {
    annotationId: string;
    label: string;
    userInterviewId: string;
  },
  { state: RootState }
>(
  "user-interviews/delete-annotation",
  async ({ annotationId, label, userInterviewId }, { getState }) => {
    let data: StateProps["data"] = getState().userInterviews.data || initialState.data;
    let error: StateProps["error"] = initialState.error;

    const mainApi = getMainApi();

    const result = await mainApi.fetch({
      schema: z.object({
        status: z.literal(200),
        body: InterviewAnnotationSchema,
      }),
      method: "PUT",
      path: `/conversations/annotations/${annotationId}/label`,
      body: label,
    });

    if (result.failure) {
      error = result.failure;
    } else {
      if (data && data[userInterviewId] && data[userInterviewId] !== undefined) {
        const prevInterview = data[userInterviewId] as UserInterviewState;
        const updatedAnnotations = prevInterview.data.annotations?.map((annotation) => {
          if (annotation.id === annotationId) {
            return {
              ...annotation,
              label: result.response.body.label,
            };
          }
          return annotation;
        });

        data = {
          ...data,
          [userInterviewId]: {
            ...prevInterview,
            data: {
              ...prevInterview.data,
              annotations: updatedAnnotations,
            },
          },
        };
      }
    }
    // The value we return becomes the `fulfilled` action payload
    return {
      data,
      error,
    };
  }
);

/**
 * Delete User Interview Annotation
 * @example await/void dispatch(deleteUserInterviewAnnotation({ annotationId, userInterviewId }));
 */
export const deleteUserInterviewAnnotation = createAsyncThunk<
  Partial<StateProps>,
  {
    annotationId: string;
    userInterviewId: string;
  },
  { state: RootState }
>("user-interviews/delete-annotation", async ({ annotationId, userInterviewId }, { getState }) => {
  let data: StateProps["data"] = getState().userInterviews.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.literal(200),
    }),
    method: "DELETE",
    path: `/conversations/annotations/${annotationId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    if (data && data[userInterviewId] && data[userInterviewId] !== undefined) {
      const prevInterview = data[userInterviewId] as UserInterviewState;
      const updatedAnnotations = prevInterview.data.annotations?.filter(
        (annotation) => annotation.id !== annotationId
      );

      data = {
        ...data,
        [userInterviewId]: {
          ...prevInterview,
          data: {
            ...prevInterview.data,
            annotations: updatedAnnotations,
          },
        },
      };
    }
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});
