// Redux toolkit
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState } from "store";
import { History, StateProps } from "./types";
// Schemas
import { HistorySchema } from "./schemas";
// Initial State
import { initialState } from "./initial-state";
// Main Api
import { getMainApi } from "store/utils/main-api";
// Zod
import { z } from "zod";

/**
 * Fetch History by project Id
 * @example await/void dispatch(geHistory({ projectId }));
 */
export const getHistory = createAsyncThunk<
  Partial<StateProps>,
  { projectId: string },
  { state: RootState }
>("history/fetch", async ({ projectId }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: History[] }>>({
    schema: z.object({
      status: z.literal(200),
      body: z.array(HistorySchema),
    }),
    skipParsing: false,
    method: "GET",
    path: `/studies/history/${projectId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = result.response.body;
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Reset History
 * @example await/void dispatch(resetHistory());
 */
export const resetHistory = createAction("history/reset");
