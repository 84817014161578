// Redux toolkit store config
import { configureStore } from "@reduxjs/toolkit";
// Web Sockets Middleware config
import { webSocketMiddleware } from "./middleware/websockets";
import {
  WebSocketConnectionConfigKeys,
  WebSocketConnectionsConfig,
} from "./middleware/websockets/types";
// Modules Web Sockets Config
import { summariesWSConnectionConfig } from "./modules/summaries/websockets";
import { userInterviewsWSConnectionConfig } from "./modules/user-interviews/websockets";
import { summariesConversationsWSConnectionConfig } from "./modules/summaries-conversations/websockets";
import { userInterviewConversationsWSConnectionConfig } from "./modules/user-interview-conversations/websockets";
// Reducers
import { reducer as audiences } from "./modules/audiences/reducer";
import { reducer as customScript } from "./modules/custom-script/reducer";
import { reducer as history } from "./modules/history/reducer";
import { reducer as problems } from "./modules/problems/reducer";
import { reducer as researchGoal } from "./modules/research-goal/reducer";
import { reducer as solutions } from "./modules/solutions/reducer";
import { reducer as study } from "./modules/study/reducer";
import { reducer as summaryConversations } from "./modules/summaries-conversations/reducer";
import { reducer as summary } from "./modules/summaries/reducer";
import { reducer as syntheticUsers } from "./modules/synthetic-users/reducer";
import { reducer as userInterviewConversations } from "./modules/user-interview-conversations/reducer";
import { reducer as userInterviews } from "./modules/user-interviews/reducer";
import { reducer as projects } from "./modules/projects/reducer";
import { reducer as workspaces } from "./modules/workspaces/reducer";
import { reducer as currentUser } from "./modules/users/reducer";
import { reducer as researchAssistant } from "./modules/research-assistant/reducer";
import { reducer as ssoProvider } from "./modules/sso-provider/reducer";
import { reducer as surveys } from "./modules/surveys/reducer";
import { reducer as subscription } from "./modules/subscription/reducer";
import { reducer as studyVisualization } from "./modules/study-visualization/reducer";
import { reducer as knowledgeGraph } from "./modules/knowledge-graph/reducer";
import { reducer as plans } from "./modules/plans/reducer";
import { reducer as releaseNotes } from "./modules/release-notes/reducer";
import { ragApi, ragReducer } from "./modules/rag/slice";

const webSocketsConnectionsConfig = {
  [WebSocketConnectionConfigKeys.UserInterviews]: userInterviewsWSConnectionConfig,
  [WebSocketConnectionConfigKeys.UserInterviewConversation]:
    userInterviewConversationsWSConnectionConfig,
  [WebSocketConnectionConfigKeys.Summary]: summariesWSConnectionConfig,
  [WebSocketConnectionConfigKeys.SummaryConversation]: summariesConversationsWSConnectionConfig,
  // Additional connections can be configured similarly
} as unknown as WebSocketConnectionsConfig;

export const store = configureStore({
  reducer: {
    audiences,
    customScript,
    history,
    problems,
    researchGoal,
    solutions,
    study,
    summary,
    syntheticUsers,
    userInterviews,
    userInterviewConversations,
    projects,
    summaryConversations,
    currentUser,
    researchAssistant,
    ssoProvider,
    surveys,
    workspaces,
    subscription,
    studyVisualization,
    knowledgeGraph,
    plans,
    releaseNotes,
    ...ragReducer,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(webSocketMiddleware(webSocketsConnectionsConfig))
      .concat(ragApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
