// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import { addResearchGoal, resetResearchGoal } from "./actions";
import { updateResearchGoalSideEffect } from "../study/actions/side-effects";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder.addCase(resetResearchGoal, () => initialState);
  builder.addCase(updateResearchGoalSideEffect, (state, action) => ({
    ...state,
    data: action.payload.researchGoal ? action.payload.researchGoal : undefined,
  }));
  builder
    // Loading start
    .addMatcher(isAnyOf(addResearchGoal.pending), (state) => ({
      ...state,
      loading: true,
    }))
    // Get History fulfilled
    .addMatcher(isAnyOf(addResearchGoal.fulfilled), (state, action) => ({
      ...state,
      ...action.payload,
    }))
    // Loading end
    .addMatcher(isAnyOf(addResearchGoal.fulfilled, addResearchGoal.rejected), (state) => ({
      ...state,
      loading: false,
    }));
});
