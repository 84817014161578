import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { initialState } from "./initial-state";
import { StateProps } from "./types";
import { getPlans, resetPlans } from "./actions";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  // Reset sso provider
  builder.addCase(resetPlans, () => initialState);

  builder
    .addMatcher(isAnyOf(getPlans.pending), (state) => ({
      ...state,
      loading: true,
    }))
    .addMatcher(isAnyOf(getPlans.fulfilled), (state, action) => ({
      ...state,
      ...action.payload,
    }))
    .addMatcher(isAnyOf(getPlans.fulfilled, getPlans.rejected), (state) => ({
      ...state,
      loading: false,
    }));
});
