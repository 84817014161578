// Redux toolkit
import { createAction } from "@reduxjs/toolkit";

export const resetCustomScript = createAction("custom-script/reset");

/**
 * Add Custom Script
 * @example await/void dispatch(addCustomScript(customScript));
 */
export const addCustomScript = createAction(
  "custom-script/add",
  function prepare(customScript: string) {
    return {
      payload: {
        customScript,
        questionsNumber: customScript?.split("\n").filter((line) => line.length).length,
      },
    };
  }
);
