// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState, store } from "store";
import { StateProps, KnowledgeGraph } from "./types";
// Schemas
import { KnowledgeGraphSchema, KnowledgeGraphStatus } from "./schemas";
// Initial State
import { initialState } from "./initial-state";
// Main Api
import { getMainApi } from "store/utils/main-api";
// Zod
import { z } from "zod";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";

let polling = 0;
let pollingTO: ReturnType<typeof setTimeout | typeof clearTimeout> = undefined;

/**
 * Generate Knowledge Graph
 * @example await/void dispatch(generateKnowledgeGraph({ studyId }));
 */
export const generateKnowledgeGraph = createAsyncThunk<
  Partial<StateProps>,
  {
    studyId: string;
  },
  { state: RootState }
>("knowledge-graph/generate", async ({ studyId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: KnowledgeGraph }>>({
    schema: z.object({
      status: z.literal(200),
      body: KnowledgeGraphSchema,
    }),
    skipParsing: false,
    method: "GET",
    path: `/knowledgeGraph/generate_graph_data/${studyId}`,
  });

  if (result.failure) {
    error = result.failure;
    return {
      error,
    };
  } else {
    dispatch(generatingKnowledgeGraph());
    setTimeout(() => {
      dispatch(pollKnowledgeGraph({ studyId }));
    }, 1000);
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
  };
});

/**
 * Poll Knowledge Graph
 * @example await/void dispatch(pollKnowledgeGraph({ studyId }));
 */
export const pollKnowledgeGraph = createAsyncThunk<
  Partial<StateProps>,
  {
    studyId: string;
  },
  { state: RootState }
>("knowledge-graph/poll", async ({ studyId }, { getState, dispatch }) => {
  let data: StateProps["data"] = getState().knowledgeGraph.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  // clear timeout if pollingTO is defined on init
  if (pollingTO !== undefined) {
    pollingTO = clearTimeout(pollingTO);
  }

  // enable polling
  polling += 1;

  const mainApi = getMainApi();

  // Total two minutes (60 * 2000msecs)
  const result = await mainApi.fetch<
    z.ZodType<
      | {
          status: 200;
          body: KnowledgeGraph;
        }
      | {
          status: 404;
          body: { detail: string };
        }
      | {
          status: 200;
          body: null;
        }
    >
  >({
    schema: z.object({
      status: z.literal(200),
      body: KnowledgeGraphSchema,
    }),
    skipParsing: false,
    method: "GET",
    path: `/knowledgeGraph/graph_visualization/${studyId}/done`,
  });

  // Error handling
  if (result.failure) {
    error = result.failure;

    if (result.failure == "not-found") {
      error = undefined;
    }
  } else if (result.response.status === 404) {
    error = undefined;
  } else {
    // Success
    const payload = result.response.body;

    if (payload?.status === KnowledgeGraphStatus.FAILED) {
      error = payload.message as TransportFailure;
      dispatch(resetGeneratingKnowledgeGraph());
    } else if (
      payload?.status === KnowledgeGraphStatus.CREATED ||
      payload?.status === KnowledgeGraphStatus.RUNNING ||
      payload === null
    ) {
      data = {
        knowledgeGraph: payload || undefined,
      };

      dispatch(generatingKnowledgeGraph());
      pollingTO = setTimeout(() => {
        if (polling) {
          polling -= 1;
          dispatch(pollKnowledgeGraph({ studyId }));
        }
      }, 5000);
    } else {
      // Status is done
      // disable polling
      polling -= 1;
      if (pollingTO !== undefined) {
        pollingTO = clearTimeout(pollingTO);
      }

      data = {
        knowledgeGraph: payload,
      };
    }
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Generating Knowledge Graph
 * @example dispatch(generatingKnowledgeGraph());
 */
export const generatingKnowledgeGraph = createAction("knowledge-graph/generating");

/**
 * Reset Generating Knowledge Graph
 * @example dispatch(resetGeneratingKnowledgeGraph());
 */
export const resetGeneratingKnowledgeGraph = createAction("knowledge-graph/reset-generating");

/**
 * Reset Knowledge Graph
 * @example dispatch(resetKnowledgeGraph());
 */
export const resetKnowledgeGraph = createAction("knowledge-graph/reset");

/**
 * Kill Knowledge Graph Polling
 * @example killKnowledgeGraphPolling();
 */
export const killKnowledgeGraphPolling = () => {
  polling = 0;
  store.dispatch(resetGeneratingKnowledgeGraph());
  if (pollingTO !== undefined) {
    pollingTO = clearTimeout(pollingTO);
  }
};
