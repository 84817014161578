import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { z } from "zod";
import { StateProps, CurrentUser } from "../types";
import { RootState } from "store";
import { getMainApi } from "store/utils/main-api";
import { UserSchema } from "../schemas";

/**
 * Get Current User
 * @example await/void dispatch(getCurrentUser());
 */
export const getCurrentUser = createAsyncThunk<
  { data: StateProps["data"]; error: StateProps["error"] },
  void,
  { state: RootState }
>("users/fetch-current-user", async (_, { getState }) => {
  let data: StateProps["data"] = getState().currentUser.data;
  let error: StateProps["error"] = getState().currentUser.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: CurrentUser }>>({
    schema: z.object({
      status: z.literal(200),
      body: UserSchema,
    }),
    skipParsing: false,
    method: "GET",
    path: `/users/current`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      currentUser: result.response.body,
    };
  }

  return {
    data,
    error,
  };
});

/**
 * Reset Current User
 * @example dispatch(resetCurrentUser());
 */
export const resetCurrentUser = createAction("users/reset-current-user");
