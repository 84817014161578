import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Audience } from "../../audiences/types";
import { Problem } from "../../problems/types";
import { Solution } from "../../solutions/types";
import { ResearchGoal } from "../../research-goal/types";
import { GeneratedSyntheticUser } from "../../synthetic-users/types";
import { UserInterview } from "../../user-interviews/types";
import { Summary } from "../../summaries/types";
import { StateProps, Study } from "../types";
import { RootState } from "store";
import { initialState } from "../initial-state";
import { getMainApi } from "store/utils/main-api";
import { z } from "zod";
import { StudySchema } from "../schemas";
import { resetStudyVisualization } from "../../study-visualization/actions";
import { resetSummary } from "../../summaries/actions";
import { resetKnowledgeGraph } from "../../knowledge-graph/actions";
import { markResearchAssistantAsCompleted } from "store/modules/research-assistant/actions";
import { resetSyntheticUsers } from "store/modules/synthetic-users/actions";
import { resetUserInterviews } from "store/modules/user-interviews/actions";
import { resetUserInterviewConversations } from "store/modules/user-interview-conversations/actions";
import { resetSummaryConversations } from "store/modules/summaries-conversations/actions";
import { getStudyStrategyCode, StudyStrategyCodes } from "store/utils/get-study-strategy-code";

/**
 * Update history as side effect of study update
 * @example await/void dispatch(updateStudyHistorySideEffect({ id, description }));
 */
export const updateStudyHistorySideEffect = createAction<{ id: string; description: string }>(
  "study/update-study-history-side-effect"
);

/**
 * Update history as side effect of study deletion
 * @example await/void dispatch(deleteStudyHistorySideEffect({ id }));
 */
export const deleteStudyHistorySideEffect = createAction<{ id: string }>(
  "study/delete-study-history-side-effect"
);

/**
 * Update audiences as side effect of study update
 * @example await/void dispatch(updateAudiencesSideEffect({ audiences }));
 */
export const updateAudiencesSideEffect = createAction<{ audiences: Audience[] }>(
  "study/update-audiences"
);

/**
 * Update problems as side effect of study update
 * @example await/void dispatch(updateProblemsSideEffect({ problems }));
 */
export const updateProblemsSideEffect = createAction<{ problems: Problem[] }>(
  "study/update-problems"
);

/**
 * Update solutions as side effect of study update
 * @example await/void dispatch(updateSolutionsSideEffect({ solution }));
 */
export const updateSolutionsSideEffect = createAction<{ solution: Solution | null | undefined }>(
  "study/update-solutions"
);

/**
 * Update research goal as side effect of study update
 * @example await/void dispatch(updateResearchGoalSideEffect({ researchGoal }));
 */
export const updateResearchGoalSideEffect = createAction<{
  researchGoal: ResearchGoal | null | undefined;
}>("study/update-research-goal");

/**
 * Update custom script as side effect of study update
 * @example await/void dispatch(updateCustomScriptSideEffect({ customScript, questionsNumber }));
 */
export const updateCustomScriptSideEffect = createAction<{
  customScript: string | undefined;
  questionsNumber: number | undefined;
}>("study/update-custom-script");

/**
 * Update synthetic users as side effect of study update
 * @example await/void dispatch(updateSyntheticUsersSideEffect({ syntheticUsers }));
 */
export const updateSyntheticUsersSideEffect = createAction<{
  syntheticUsers: GeneratedSyntheticUser[] | null | undefined;
}>("study/update-synthetic-users");

/**
 * Update user interviews as side effect of study update
 * @example await/void dispatch(updateUserInterviewsSideEffect({ userInterviews }));
 */
export const updateUserInterviewsSideEffect = createAction<{ userInterviews: UserInterview[] }>(
  "study/update-user-interviews"
);

/**
 * Update summary as side effect of study update
 * @example await/void dispatch(updateSummarySideEffect({ summary }));
 */
export const updateSummarySideEffect = createAction<{ summary: Summary[] | null | undefined }>(
  "study/update-summary"
);

/**
 * Populate study
 * @example await/void dispatch(populateStudy({ studyId }));
 */
export const populateStudy = createAsyncThunk<
  Partial<StateProps>,
  { studyId: string },
  { state: RootState }
>("study/populate", async ({ studyId }, { dispatch }) => {
  let data: StateProps["data"] = initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Study }>>({
    schema: z.object({
      status: z.literal(200),
      body: StudySchema,
    }),
    skipParsing: false,
    method: "GET",
    path: `/studies/${studyId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = result.response.body;
    // TODO: discuss types with BE
    const {
      audiences,
      problems,
      solution,
      researchGoal,
      generatedSyntheticUsers: syntheticUsers,
      userInterviews,
      summaries,
      studyStrategy,
    } = result.response.body;

    const customScript =
      studyStrategy === "Custom Script" ? userInterviews[0]?.topics?.join("\n") : undefined;

    const questionsNumber =
      studyStrategy === "Custom Script" ? userInterviews[0]?.topics?.length : undefined;

    dispatch(updateAudiencesSideEffect({ audiences }));
    dispatch(updateProblemsSideEffect({ problems }));
    dispatch(updateSolutionsSideEffect({ solution }));
    dispatch(updateResearchGoalSideEffect({ researchGoal }));
    dispatch(updateCustomScriptSideEffect({ customScript, questionsNumber }));
    !!syntheticUsers?.length && dispatch(updateSyntheticUsersSideEffect({ syntheticUsers }));
    !!userInterviews.length && dispatch(updateUserInterviewsSideEffect({ userInterviews }));
    dispatch(summaries?.length ? updateSummarySideEffect({ summary: summaries }) : resetSummary());
    dispatch(resetStudyVisualization());
    dispatch(resetKnowledgeGraph());
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

export const cloneStudy = createAsyncThunk<
  Partial<StateProps>,
  { onSuccess: (studyStrategy: StudyStrategyCodes) => void },
  { state: RootState }
>("study/clone", ({ onSuccess }, { getState, dispatch }) => {
  const data: StateProps["data"] = getState().study.data || initialState.data;
  const error: StateProps["error"] = initialState.error;

  const audiences = getState().study.data?.audiences;
  const problems = getState().study.data?.problems;
  const solution = getState().study.data?.solution;
  const researchGoal = getState().study.data?.researchGoal;
  const studyStrategy = getState().study.data?.studyStrategy;
  const userInterviews = getState().study.data?.userInterviews;
  const customScript =
    studyStrategy === "Custom Script" ? userInterviews?.[0]?.topics?.join("\n") : undefined;

  const questionsNumber =
    studyStrategy === "Custom Script" ? userInterviews?.[0]?.topics?.length : undefined;

  dispatch(markResearchAssistantAsCompleted());
  audiences && dispatch(updateAudiencesSideEffect({ audiences }));
  problems && dispatch(updateProblemsSideEffect({ problems }));
  solution && dispatch(updateSolutionsSideEffect({ solution }));
  researchGoal && dispatch(updateResearchGoalSideEffect({ researchGoal }));
  studyStrategy === "Custom Script" &&
    dispatch(updateCustomScriptSideEffect({ customScript, questionsNumber }));
  dispatch(resetSyntheticUsers());
  dispatch(resetUserInterviews());
  dispatch(resetUserInterviewConversations());
  dispatch(resetSummary());
  dispatch(resetSummaryConversations());
  dispatch(resetStudyVisualization());
  dispatch(resetKnowledgeGraph());

  studyStrategy && onSuccess(getStudyStrategyCode(studyStrategy));

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});
