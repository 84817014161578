// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import {
  addSolution,
  resetSolutions,
  resetSelectedSolution,
  updateSolution,
} from "./actions/index";
import { LoadingStatus } from "store/types";
import { updateSolutionsSideEffect } from "../study/actions/side-effects";
import { updateSolutionsProjectSideEffect } from "./actions/side-effects";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  // Reset solutions
  builder.addCase(resetSolutions, () => initialState);
  builder.addCase(updateSolutionsProjectSideEffect, (state, action) => ({
    ...state,
    error: action.payload.error,
    data: {
      solutions: action.payload.solutions,
      selectedSolutionId: [],
      selectedSolutions: [],
    },
  }));
  // Reset selected solution
  builder.addCase(updateSolutionsSideEffect, (state, action) => ({
    ...state,
    data: {
      solutions: action.payload.solution ? [action.payload.solution] : [],
      selectedSolutionId: action.payload.solution ? [action.payload.solution.id] : [],
      selectedSolutions: action.payload.solution ? [action.payload.solution] : [],
    },
  }));
  // Reset selected solution
  builder.addCase(resetSelectedSolution, (state) => ({
    ...state,
    data: {
      ...state.data,
      selectedSolutionId: [],
      selectedSolutions: [],
    },
  }));
  builder;
  builder
    // Loading start
    .addMatcher(isAnyOf(addSolution.pending, updateSolution.pending), (state) => ({
      ...state,
      loading: LoadingStatus.single,
    }))
    // Get + Add + Duplicate + Update + Delete Solutions fulfilled
    .addMatcher(isAnyOf(addSolution.fulfilled, updateSolution.fulfilled), (state, action) => ({
      ...state,
      ...action.payload,
    }))
    // Loading end
    .addMatcher(
      isAnyOf(
        addSolution.fulfilled,
        addSolution.rejected,
        updateSolution.fulfilled,
        updateSolution.rejected
      ),
      (state) => ({
        ...state,
        loading: LoadingStatus.none,
      })
    );
});
