import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { z } from "zod";
import { RedeemSubscriptionResponse, StateProps } from "./types";
import { RootState } from "store";
import { getMainApi } from "store/utils/main-api";
import { getWorkspaceById } from "../workspaces/actions";
import { RedeemSubscriptionResponseSchema } from "./schema";

/**
 * Redeem Subscription
 * @example await/void dispatch(redeemSubscription({ workspaceId, token, onSuccess, onError }));
 */
export const redeemSubscription = createAsyncThunk<
  { error: StateProps["error"] },
  { workspaceId: string; token: string; onSuccess?: () => void; onError?: () => void },
  { state: RootState }
>(
  "subscription/redeem",
  async ({ workspaceId, token, onSuccess, onError }, { getState, dispatch }) => {
    let error: StateProps["error"] = getState().subscription.error;

    const mainApi = getMainApi();

    const result = await mainApi.fetch<
      z.ZodType<{ status: 200; body: RedeemSubscriptionResponse }>
    >({
      schema: z.object({
        status: z.literal(200),
        body: RedeemSubscriptionResponseSchema,
      }),
      skipParsing: false,
      method: "POST",
      path: `/subscription/redeem`,
      body: {
        workspace_id: workspaceId,
        token,
      },
    });

    if (result.failure) {
      error = result.failure;

      onError && onError();
    } else {
      onSuccess && onSuccess();

      dispatch(getWorkspaceById({ workspaceId }));
    }

    return {
      error,
    };
  }
);

/**
 * Reset Subscription
 * @example await/void dispatch(resetSubscription());
 */
export const resetSubscription = createAction("subscription/reset");
