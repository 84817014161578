// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import { resetAudiences, resetSelectedAudiences } from "./actions/index";
import { addAudience, updateAudience } from "./actions";
import { LoadingStatus } from "store/types";
import { updateAudiencesSideEffect } from "../study/actions/side-effects";
import { updateAudiencesProjectSideEffect } from "./actions/side-effects";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  // Reset audiences
  builder.addCase(resetAudiences, () => initialState);
  builder.addCase(updateAudiencesProjectSideEffect, (state, action) => ({
    ...state,
    error: action.payload.error,
    data: {
      audiences: action.payload?.audiences,
      selectedAudiencesIds: [],
      selectedAudiences: [],
    },
  }));
  // Reset selected problems
  builder.addCase(updateAudiencesSideEffect, (state, action) => ({
    ...state,
    data: {
      ...state.data,
      selectedAudiencesIds: action.payload.audiences.map((audience) => audience.id),
      selectedAudiences: action.payload.audiences,
    },
  }));
  builder.addCase(resetSelectedAudiences, (state) => ({
    ...state,
    data: {
      ...state.data,
      selectedAudiencesIds: [],
      selectedAudiences: [],
    },
  }));
  builder
    // Loading start (single)
    .addMatcher(isAnyOf(addAudience.pending, updateAudience.pending), (state) => ({
      ...state,
      loading: LoadingStatus.single,
    }))
    // Get + Add + Duplicate + Update + Delete Problems fulfilled
    .addMatcher(isAnyOf(addAudience.fulfilled, updateAudience.fulfilled), (state, action) => ({
      ...state,
      ...action.payload,
    }))
    // Loading end
    .addMatcher(
      isAnyOf(
        addAudience.fulfilled,
        addAudience.rejected,
        updateAudience.fulfilled,
        updateAudience.rejected
      ),
      (state) => ({
        ...state,
        loading: LoadingStatus.none,
      })
    );
});
