import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";
import { UnifiedError } from "./custom-base-query";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

export function isCustomError(
  error: unknown
): error is { status: "CUSTOM_ERROR"; error: TransportFailure } {
  return (
    typeof error === "object" &&
    error !== null &&
    "status" in error &&
    error.status === "CUSTOM_ERROR" &&
    "error" in error &&
    Object.values(TransportFailure).includes(error.error as TransportFailure)
  );
}

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return (
    typeof error === "object" &&
    error !== null &&
    "status" in error &&
    (error.status === "FETCH_ERROR" ||
      error.status === "PARSING_ERROR" ||
      error.status === "TIMEOUT_ERROR" ||
      typeof error.status === "number")
  );
}

export function parseError(error: unknown): UnifiedError | undefined {
  if (isCustomError(error)) {
    return error;
  }
  if (isFetchBaseQueryError(error)) {
    return error;
  }
  return undefined;
}
