import { createAsyncThunk } from "@reduxjs/toolkit";
import { z } from "zod";
import { ReleaseNote, StateProps } from "./types";
import { RootState } from "store";
import { getMainApi } from "store/utils/main-api";
import { ReleaseNoteSchema } from "./schema";
import { initialState } from "./initial-state";

/**
 * Redeem Subscription
 * @example await/void dispatch(redeemSubscription({ workspaceId, token, onSuccess, onError }));
 */
export const getReleaseNotes = createAsyncThunk<
  { error: StateProps["error"] },
  void,
  { state: RootState }
>("subscription/redeem", async () => {
  let error: StateProps["error"] = initialState.error;
  let data: StateProps["data"] = initialState.data;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: ReleaseNote[] }>>({
    schema: z.object({
      status: z.literal(200),
      body: z.array(ReleaseNoteSchema),
    }),
    skipParsing: false,
    method: "GET",
    path: `/releaseNotes`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = result.response.body;
  }

  return {
    data,
    error,
  };
});
