// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState, store } from "store";
import { StateProps, SummaryMessage } from "./types";
// Schemas
import { SummaryInAnyPhaseSchema } from "./schemas";
// Initial State
import { initialState } from "./initial-state";
// Zod
import { WasClosed, WebSocketConnectionConfigKeys } from "store/middleware/websockets/types";
import { getMainApi } from "store/utils/main-api";
import { z } from "zod";

export const GENERATE_SUMMARY_TYPE = "summary/generate";

/**
 * Generate Summary
 * @example await/void dispatch(generateSummary({ summarizationFocus }));
 */
export const generateSummary = createAsyncThunk<
  Partial<StateProps>,
  { summarizationFocus?: string },
  { state: RootState }
>(GENERATE_SUMMARY_TYPE, async ({ summarizationFocus }, { getState, dispatch }) => {
  let data: StateProps["data"] = getState().summary.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const { audiences, solutions, problems, projects, userInterviews } = getState();

  const mainApi = getMainApi();

  const body = {
    syntheticUserIds: audiences.data.selectedAudiencesIds,
    problemsIds: problems.data?.selectedProblemsIds ?? [],
    solutionId: solutions.data.selectedSolutionId && solutions.data.selectedSolutionId[0],
    projectId: projects.data.project?.id,
    userInterviewsIds: Object.keys(userInterviews.data || {}),
    summarizationFocus,
  };

  const result = await mainApi.fetch({
    schema: z.union([
      z.object({
        status: z.literal(502),
        body: z.object({
          message: z.literal("too_many_tokens"),
        }),
      }),

      z.object({
        status: z.literal(200),
        body: SummaryInAnyPhaseSchema,
      }),
    ]),
    path: `/summaries/generate`,
    method: "POST",
    body,
  });

  if (result.failure) {
    error = result.failure;
  } else if (result.response.status === 502) {
    error = "too_many_tokens";
  } else {
    // Set Initial Summary Data
    const { summaryId, studyId, projectId, userInterviews, createdAt, saved, summarizationFocus } =
      result.response.body;

    data = [
      {
        summary: "",
        messages: undefined,
        summaryId,
        studyId,
        projectId,
        userInterviews,
        createdAt,
        saved,
        summarizationFocus,
      },
      ...(data ? data : []),
    ];

    // Connect to WS to stream summary
    dispatch(connectSummaryWS(summaryId));
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

export const CONNECT_SUMMARY_WS_ACTION_TYPE = "summaries/connect-ws";

/**
 * Connect to Summary websocket
 * @example await/void dispatch(connectSummaryWS(summaryId));
 */
export const connectSummaryWS = createAction(
  CONNECT_SUMMARY_WS_ACTION_TYPE,
  function prepare(summaryId: string) {
    return {
      payload: {
        key: `${WebSocketConnectionConfigKeys.Summary}-${summaryId}`,
        resourceId: summaryId,
      },
    };
  }
);

/**
 * Stream Summary
 * @example dispatch(streamSummary(messages));
 */
export const streamSummary = createAction<SummaryMessage[]>("summaries/stream");

export const DISCONNECT_SUMMARY_STREAMING_ACTION_TYPE = "summaries/disconnect-ws";

/**
 * Disconnect Summary websocket
 * @example await/void dispatch(disconnectSummaryWS(summaryId, wasClosed));
 */
export const disconnectSummaryWS = createAction(
  DISCONNECT_SUMMARY_STREAMING_ACTION_TYPE,
  function prepare(summaryId: string, wasClosed?: WasClosed) {
    return {
      payload: {
        key: `${WebSocketConnectionConfigKeys.Summary}-${summaryId}`,
        resourceId: summaryId,
        wasClosed,
      },
    };
  }
);

const BATCH_SIZE = 5;

let batchedMessages: SummaryMessage[] = [];

export const handleSummaryMessage = (newMessage: SummaryMessage) => {
  const currMessages: SummaryMessage[] = [...batchedMessages, newMessage];

  // Check if we have reached the batch size
  if (currMessages.length >= BATCH_SIZE) {
    // Dispatch the batched messages
    store.dispatch(streamSummary(currMessages));

    // Clear the batched messages array
    batchedMessages = [];
  } else {
    batchedMessages = currMessages;
  }
};

export const handleRemainingBatchedMessages = () => {
  if (batchedMessages.length) {
    // Dispatch the remaining batched messages
    store.dispatch(streamSummary(batchedMessages));
    batchedMessages = [];
  }
};

/**
 * Export Summary
 * @example await/void dispatch(exportSummary({ summaryId, studyDescription, fileExtension }));
 */
export const exportSummary = createAsyncThunk<
  Partial<StateProps>,
  { summaryId: string; studyDescription: string; fileExtension: string },
  { state: RootState }
>("summaries/export", async ({ summaryId, studyDescription, fileExtension }) => {
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.literal(200),
      body: z.unknown(),
    }),
    method: "GET",
    path: `/summaries/export/${summaryId}?file_extension=${fileExtension}`,
    skipParsing: true,
    isFileDownload: true,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    const blob = new Blob([result.response.body as Blob], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${studyDescription}.${fileExtension}`;
    a.click();
    // Remove the link after the download starts
    setTimeout(() => {
      a.remove();
      URL.revokeObjectURL(url);
    }, 100);
  }
  // The value we return becomes the `fulfilled` action payload
  return {
    error,
  };
});

/**
 * Reset Summary
 * @example dispatch(resetSummary());
 */
export const resetSummary = createAction("summaries/reset");
