import { createAsyncThunk } from "@reduxjs/toolkit";
import { StateProps } from "../types";
import { RootState } from "store";
import { getMainApi } from "store/utils/main-api";
import { initialState } from "../initial-state";
import { z } from "zod";
import { WorkspaceInviteResponseSchema, WorkspaceMemberSchema } from "../schemas";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";
import { getWorkspaceById, getWorkspacesList } from ".";

/**
 * Accept Workspace Invite
 * @example await/void dispatch(acceptWorkspaceInvite({ workspaceInviteId, workspaceId, onSuccess }));
 */
export const acceptWorkspaceInvite = createAsyncThunk<
  Partial<StateProps>,
  { workspaceInviteId: string; workspaceId: string; onSuccess?: () => void },
  { state: RootState }
>(
  "workspaces/accept-invite",
  async ({ workspaceInviteId, workspaceId, onSuccess }, { dispatch }) => {
    let error: StateProps["error"] = initialState.error;

    const mainApi = getMainApi();

    const result = await mainApi.fetch({
      schema: z.object({
        status: z.union([
          z.literal(200),
          z.literal(401),
          z.literal(404),
          z.literal(409),
          z.literal(500),
        ]),
        body: z.union([WorkspaceInviteResponseSchema, z.object({ detail: z.string() })]),
      }),
      method: "POST",
      path: `/workspaceInvites/accept/${workspaceInviteId}`,
    });

    if (result.failure) {
      error = result.failure;
    } else {
      // Handle specific errors
      if (result.response.status === 500) {
        error = TransportFailure.InternalServerError;
      }

      if ("detail" in result.response.body) {
        switch (result.response.status) {
          case 401: {
            if (result.response.body.detail === TransportFailure.UserUnautorized) {
              error = TransportFailure.UserUnautorized;
            }
            if (result.response.body.detail === TransportFailure.WorkspaceInviteUserNotInvited) {
              error = TransportFailure.WorkspaceInviteUserNotInvited;
            }
            break;
          }
          case 404: {
            if (result.response.body.detail === TransportFailure.WorkspaceInviteNotFound) {
              error = TransportFailure.WorkspaceInviteNotFound;
            }
            break;
          }
          case 409: {
            if (result.response.body.detail === TransportFailure.WorkspaceInviteUserAlreadyMember) {
              error = TransportFailure.WorkspaceInviteUserAlreadyMember;
            }
            break;
          }
          case 500: {
            if (result.response.body.detail === TransportFailure.InternalServerError) {
              error = TransportFailure.InternalServerError;
            }
            break;
          }
        }
      }

      if (!error) {
        onSuccess && onSuccess();
        dispatch(getWorkspaceById({ workspaceId }));
        dispatch(getWorkspacesList());
      }
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      error,
    };
  }
);

/**
 * Reject Workspace Invite
 * @example await/void dispatch(rejectWorkspaceInvite({ workspaceInviteId }));
 */
export const rejectWorkspaceInvite = createAsyncThunk<
  Partial<StateProps>,
  { workspaceInviteId: string },
  { state: RootState }
>("workspaces/reject-invite", async ({ workspaceInviteId }, { dispatch }) => {
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.union([z.literal(200), z.literal(401), z.literal(404), z.literal(500)]),
      body: z.union([WorkspaceInviteResponseSchema, z.object({ detail: z.string() })]),
    }),
    method: "POST",
    path: `/workspaceInvites/reject/${workspaceInviteId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    // Handle specific errors
    if (result.response.status === 500) {
      error = TransportFailure.InternalServerError;
    }

    if ("detail" in result.response.body) {
      switch (result.response.status) {
        case 401: {
          if (result.response.body.detail === TransportFailure.WorkspaceInviteUserNotInvited) {
            error = TransportFailure.WorkspaceInviteUserNotInvited;
          }
          if (result.response.body.detail === TransportFailure.WorkspaceInviteUserNotInvited) {
            error = TransportFailure.WorkspaceInviteUserNotInvited;
          }
          break;
        }
        case 404: {
          if (result.response.body.detail === TransportFailure.WorkspaceInviteNotFound) {
            error = TransportFailure.WorkspaceInviteNotFound;
          }
          break;
        }
        case 500: {
          if (result.response.body.detail === TransportFailure.InternalServerError) {
            error = TransportFailure.InternalServerError;
          }
          break;
        }
      }
    }

    if (!error) {
      // TODO: why does the remove endpoint return the updated list of members and this one doesn't?
      dispatch(getWorkspacesList());
    }
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    error,
  };
});

/**
 * Remove Workspace Invite
 * @example await/void dispatch(removeWorkspaceInvite({ workspaceInviteId }));
 */
export const removeWorkspaceInvite = createAsyncThunk<
  { data: StateProps["data"]; error: StateProps["error"] },
  { workspaceInviteId: string },
  { state: RootState }
>("workspaces/remove-invite", async ({ workspaceInviteId }, { getState }) => {
  let data: StateProps["data"] = getState().workspaces.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.union([z.literal(200), z.literal(401), z.literal(404), z.literal(500)]),
      body: z.array(WorkspaceMemberSchema),
    }),
    method: "DELETE",
    path: `/workspaceInvites/${workspaceInviteId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      workspaceMembers: result.response.body,
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});
