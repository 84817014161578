// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import { addProblems, updateProblem, resetProblems, resetSelectedProblems } from "./actions/index";
import { LoadingStatus } from "store/types";
import { updateProblemsSideEffect } from "../study/actions/side-effects";
import { updateProblemsProjectSideEffect } from "./actions/side-effects";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  // Reset problems
  builder.addCase(resetProblems, () => initialState);
  builder.addCase(updateProblemsProjectSideEffect, (state, action) => ({
    ...state,
    error: action.payload.error,
    data: {
      problems: action.payload.problems,
      selectedProblemsIds: [],
      selectedProblems: [],
    },
  }));
  // Reset selected problems
  builder.addCase(updateProblemsSideEffect, (state, action) => ({
    ...state,
    data: {
      problems: action.payload.problems,
      selectedProblemsIds: action.payload.problems.map((problem) => problem.id),
      selectedProblems: action.payload.problems,
    },
  }));
  // Reset selected problems
  builder.addCase(resetSelectedProblems, (state) => ({
    ...state,
    data: {
      ...state.data,
      selectedProblemsIds: [],
      selectedProblems: [],
    },
  }));
  builder
    // Loading start (single)
    .addMatcher(isAnyOf(addProblems.pending, updateProblem.pending), (state) => ({
      ...state,
      loading: LoadingStatus.single,
    }))
    // Get + Add + Duplicate + Update + Delete Problems fulfilled
    .addMatcher(isAnyOf(addProblems.fulfilled, updateProblem.fulfilled), (state, action) => ({
      ...state,
      ...action.payload,
    }))
    // Loading end
    .addMatcher(
      isAnyOf(
        addProblems.fulfilled,
        addProblems.rejected,
        updateProblem.fulfilled,
        updateProblem.rejected
      ),
      (state) => ({
        ...state,
        loading: LoadingStatus.none,
      })
    );
});
