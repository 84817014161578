import { createReducer, isAnyOf } from "@reduxjs/toolkit";

import { StateProps } from "./types";

import { initialState } from "./initial-state";

import { getReleaseNotes } from "./actions";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder
    .addMatcher(isAnyOf(getReleaseNotes.pending), (state) => ({
      ...state,
      loading: true,
    }))
    .addMatcher(isAnyOf(getReleaseNotes.fulfilled), (state, action) => ({
      ...state,
      ...action.payload,
    }))
    .addMatcher(isAnyOf(getReleaseNotes.fulfilled, getReleaseNotes.rejected), (state) => ({
      ...state,
      loading: false,
    }));
});
