// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import {
  bookmarkStudy,
  getStudy,
  deleteStudy,
  unbookmarkStudy,
  updateStudyDescription,
  resetStudy,
} from "./actions";
import { populateStudy, cloneStudy } from "./actions/side-effects";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder.addCase(resetStudy, () => initialState);
  builder
    // Loading start
    .addMatcher(
      isAnyOf(
        getStudy.pending,
        populateStudy.pending,
        updateStudyDescription.pending,
        deleteStudy.pending,
        cloneStudy.pending
      ),
      (state) => ({
        ...state,
        loading: true,
      })
    )
    // Get Study fulfilled
    .addMatcher(
      isAnyOf(
        getStudy.fulfilled,
        populateStudy.fulfilled,
        updateStudyDescription.fulfilled,
        deleteStudy.fulfilled,
        bookmarkStudy.fulfilled,
        unbookmarkStudy.fulfilled,
        cloneStudy.fulfilled
      ),
      (state, action) => ({
        ...state,
        ...action.payload,
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(
        getStudy.fulfilled,
        getStudy.rejected,
        populateStudy.fulfilled,
        populateStudy.rejected,
        updateStudyDescription.fulfilled,
        updateStudyDescription.rejected,
        deleteStudy.fulfilled,
        deleteStudy.rejected,
        cloneStudy.fulfilled,
        cloneStudy.rejected
      ),
      (state) => ({
        ...state,
        loading: false,
      })
    );
});
