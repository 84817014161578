// Redux toolkit
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState } from "store";

// Initial State
// Main Api
// Zod
import { getMainApi } from "store/utils/main-api";
import { resetAllData } from "store/utils/reset-all-data";
import { z } from "zod";
import { initialState } from "../initial-state";
import {
  WorkspaceSchema,
  WorkspaceListItemSchema,
  WorkspaceUsageDashboardSchema,
} from "../schemas";
import {
  StateProps,
  Workspace,
  WorkspaceMode,
  WorkspaceUsageDashboard,
  WorkspacesListItem,
} from "../types";
import { updateProjectWorkspaceSideEffect } from "store/modules/projects/actions/side-effetcs";
import { getHistory, resetHistory } from "store/modules/history/actions";
import { updateAudiencesProjectSideEffect } from "store/modules/audiences/actions/side-effects";
import { updateProblemsProjectSideEffect } from "store/modules/problems/actions/side-effects";
import { updateSolutionsProjectSideEffect } from "store/modules/solutions/actions/side-effects";
import { resetInsightsData } from "store/utils/reset-insights-data";
import { resetCustomScript } from "store/modules/custom-script/actions";
import { resetResearchGoal } from "store/modules/research-goal/actions";
import { CurrentUser } from "store/modules/users/types";
import { UserSchema } from "store/modules/users/schemas";
import { getPlans } from "store/modules/plans/actions";
import { ragApi, resetRag } from "store/modules/rag/slice";

/**
 * Fetch Workspaces List
 * @example await/void dispatch(getWorkspacesList());
 */
export const getWorkspacesList = createAsyncThunk<
  { data: StateProps["data"]; error: StateProps["error"] },
  void,
  { state: RootState }
>("workspaces/fetch-list", async (_, { getState }) => {
  let data: StateProps["data"] = getState().workspaces.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: WorkspacesListItem[] }>>({
    schema: z.object({
      status: z.literal(200),
      body: z.array(WorkspaceListItemSchema),
    }),
    skipParsing: false,
    method: "GET",
    // TODO: Ask BE if this endpoint should be changed
    path: `/workspaces/workspaces/list`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      workspacesList: result.response.body,
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Fetch Workspace By Id
 * @example await/void dispatch(getWorkspaceById({ workspaceId, onSuccess }));
 */
export const getWorkspaceById = createAsyncThunk<
  { data: StateProps["data"]; error: StateProps["error"] },
  { workspaceId: string; onSuccess?: (newWorkspaceName?: string) => void },
  { state: RootState }
>("workspaces/fetch-by-id", async ({ workspaceId, onSuccess }, { getState, dispatch }) => {
  let data: StateProps["data"] = getState().workspaces.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  resetInsightsData();
  dispatch(resetCustomScript());
  dispatch(resetResearchGoal());

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Workspace }>>({
    schema: z.object({
      status: z.literal(200),
      body: WorkspaceSchema,
    }),
    skipParsing: false,
    method: "GET",
    // TODO: Ask BE if this endpoint should be changed
    path: `/workspaces/workspaces/${workspaceId}`,
  });

  if (result.failure) {
    error = result.failure;
    dispatch(updateProjectWorkspaceSideEffect({ error }));
    dispatch(updateAudiencesProjectSideEffect({ error }));
    dispatch(updateProblemsProjectSideEffect({ error }));
    dispatch(updateSolutionsProjectSideEffect({ error }));
  } else {
    data = {
      workspace: result.response.body,
    };

    const newWorkspace = result.response.body;
    const project = newWorkspace?.projects[0];
    const projectsList = newWorkspace?.projects;

    // Side effects
    if (project) {
      dispatch(updateProjectWorkspaceSideEffect({ project, projectsList }));
      dispatch(updateAudiencesProjectSideEffect({ audiences: project.syntheticUsers }));
      dispatch(updateProblemsProjectSideEffect({ problems: project.problems }));
      dispatch(updateSolutionsProjectSideEffect({ solutions: project.solutions }));
      dispatch(getHistory({ projectId: project.id }));
      dispatch(
        getPlans({
          currentSubscription: newWorkspace.currentSubscriptionStripePriceId ?? undefined,
        })
      );
      dispatch(
        ragApi.endpoints.getRagFiles.initiate({ projectId: project.id }, { forceRefetch: true })
      );
      onSuccess && onSuccess(newWorkspace.name);
    }
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Fetch Latest Workspace
 * @example await/void dispatch(getLatestWorkspace());
 */
export const getLatestWorkspace = createAsyncThunk<
  { data: StateProps["data"]; error: StateProps["error"] },
  void,
  { state: RootState }
>("workspaces/fetch-latest", async (_, { getState, dispatch }) => {
  let data: StateProps["data"] = getState().workspaces.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();
  resetAllData();

  // Fetch current user
  const currentUser = await mainApi.fetch<z.ZodType<{ status: 200; body: CurrentUser }>>({
    schema: z.object({
      status: z.literal(200),
      body: UserSchema,
    }),
    method: "GET",
    path: "/users/current",
  });

  if (currentUser.failure || !currentUser.response.body.lastVisitedWorkspace) {
    const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Workspace[] }>>({
      schema: z.object({
        status: z.literal(200),
        body: z.array(WorkspaceSchema),
      }),
      method: "GET",
      path: "/workspaces/workspaces",
    });

    if (result.failure) {
      error = result.failure;

      dispatch(updateProjectWorkspaceSideEffect({ error }));
      dispatch(updateAudiencesProjectSideEffect({ error }));
      dispatch(updateProblemsProjectSideEffect({ error }));
      dispatch(updateSolutionsProjectSideEffect({ error }));
    } else {
      // TODO: double check with BE + ask for specific endpoint for this purpose
      data = {
        workspace: result.response.body[0],
      };

      const workspace = result.response.body[0];
      const project = workspace?.projects[0];
      const projectsList = workspace?.projects;

      // Side effects
      if (project && projectsList) {
        dispatch(updateProjectWorkspaceSideEffect({ project, projectsList }));
        dispatch(updateAudiencesProjectSideEffect({ audiences: project.syntheticUsers }));
        dispatch(updateProblemsProjectSideEffect({ problems: project.problems }));
        dispatch(updateSolutionsProjectSideEffect({ solutions: project.solutions }));
        dispatch(getHistory({ projectId: project.id }));
        dispatch(
          getPlans({
            currentSubscription: workspace.currentSubscriptionStripePriceId ?? undefined,
          })
        );
        dispatch(
          ragApi.endpoints.getRagFiles.initiate({ projectId: project.id }, { forceRefetch: true })
        );
      }
    }
  } else {
    const lastVisitedWorkspaceId = currentUser.response.body.lastVisitedWorkspace;
    const lastVisitedProjectId = currentUser.response.body.lastVisitedProject;

    const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Workspace }>>({
      schema: z.object({
        status: z.literal(200),
        body: WorkspaceSchema,
      }),
      skipParsing: false,
      method: "GET",
      path: `/workspaces/workspaces/${lastVisitedWorkspaceId}`,
    });

    if (result.failure) {
      error = result.failure;
      dispatch(updateProjectWorkspaceSideEffect({ error }));
      dispatch(updateAudiencesProjectSideEffect({ error }));
      dispatch(updateProblemsProjectSideEffect({ error }));
      dispatch(updateSolutionsProjectSideEffect({ error }));
    } else {
      const workspace = result.response.body;

      data = {
        workspace,
      };

      const project =
        workspace?.projects.find((project) => project.id === lastVisitedProjectId) ||
        workspace?.projects[0];
      const projectsList = workspace?.projects;

      // Side effects
      if (project && projectsList) {
        dispatch(updateProjectWorkspaceSideEffect({ project, projectsList }));
        dispatch(updateAudiencesProjectSideEffect({ audiences: project.syntheticUsers }));
        dispatch(updateProblemsProjectSideEffect({ problems: project.problems }));
        dispatch(updateSolutionsProjectSideEffect({ solutions: project.solutions }));
        dispatch(getHistory({ projectId: project.id }));
        dispatch(
          getPlans({
            currentSubscription: workspace.currentSubscriptionStripePriceId ?? undefined,
          })
        );
        dispatch(
          ragApi.endpoints.getRagFiles.initiate({ projectId: project.id }, { forceRefetch: true })
        );
      }
    }
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Add Workspace
 * Example of an async action / thunk
 * @example await/void dispatch(addWorkspace({ name, description, onSuccess }));
 */
export const addWorkspace = createAsyncThunk<
  { data: StateProps["data"]; error: StateProps["error"] },
  { name: string; description?: string; onSuccess: (workspaceId: string) => void },
  { state: RootState }
>("workspaces/add", async ({ name, description, onSuccess }, { getState, dispatch }) => {
  let data: StateProps["data"] = getState().workspaces.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  resetInsightsData();
  dispatch(resetCustomScript());
  dispatch(resetResearchGoal());

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Workspace }>>({
    schema: z.object({
      status: z.literal(200),
      body: WorkspaceSchema,
    }),
    skipParsing: false,
    method: "POST",
    path: `/workspaces`,
    body: {
      name,
      description,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      workspace: result.response.body,
      workspaceMembers: undefined,
    };

    const newWorkspace = result.response.body;
    const project = newWorkspace?.projects[0];
    const projectsList = newWorkspace?.projects;

    // Side effects
    if (project) {
      dispatch(updateProjectWorkspaceSideEffect({ project, projectsList }));
      dispatch(updateAudiencesProjectSideEffect({ audiences: project.syntheticUsers }));
      dispatch(updateProblemsProjectSideEffect({ problems: project.problems }));
      dispatch(updateSolutionsProjectSideEffect({ solutions: project.solutions }));
      dispatch(
        getPlans({
          currentSubscription: newWorkspace.currentSubscriptionStripePriceId ?? undefined,
        })
      );
      dispatch(resetHistory());
      dispatch(resetRag());
    }

    onSuccess && onSuccess(newWorkspace.id);
    dispatch(getWorkspacesList());
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Update Workspace
 * @example await/void dispatch(updateWorkspaceName({ workspaceId, name, description, onSuccess }));
 */
export const updateWorkspaceDescription = createAsyncThunk<
  { data: StateProps["data"]; error: StateProps["error"] },
  { workspaceId: string; name: string; description?: string; onSuccess: () => void },
  { state: RootState }
>(
  "workspaces/update-description",
  async ({ workspaceId, name, description, onSuccess }, { getState }) => {
    let data: StateProps["data"] = getState().workspaces.data || initialState.data;
    let error: StateProps["error"] = initialState.error;

    const mainApi = getMainApi();

    const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Workspace }>>({
      schema: z.object({
        status: z.literal(200),
        body: WorkspaceSchema,
      }),
      skipParsing: false,
      method: "PUT",
      // TODO: Ask BE if this endpoint should be changed
      path: `/workspaces/workspaces/${workspaceId}/description`,
      body: {
        name,
        description,
      },
    });

    if (result.failure) {
      error = result.failure;
    } else {
      const newWorkspace = result.response.body;
      data = {
        workspace: newWorkspace,
        workspacesList: data.workspacesList?.map((workspace) => ({
          ...workspace,
          name: workspace.id === workspaceId ? newWorkspace.name : workspace.name,
          description:
            workspace.id === workspaceId ? newWorkspace.description : workspace.description,
        })),
      };

      onSuccess();
    }

    // The value we return becomes the `fulfilled` action payload
    return {
      data,
      error,
    };
  }
);

/**
 * Delete Workspace
 * @example await/void dispatch(deleteWorkspace({ workspaceId, onSuccess }));
 */
export const deleteWorkspace = createAsyncThunk<
  { data: StateProps["data"]; error: StateProps["error"] },
  { workspaceId: string; onSuccess?: (newWorkspaceName?: string) => void },
  { state: RootState }
>("workspaces/delete", async ({ workspaceId, onSuccess }, { getState, dispatch }) => {
  let data: StateProps["data"] = getState().workspaces.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const workspacesList = getState().workspaces.data.workspacesList;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200 }>>({
    schema: z.object({
      status: z.literal(200),
    }),
    skipParsing: false,
    method: "DELETE",
    path: `/workspaces/workspaces/${workspaceId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    const remainingWorkspaces = workspacesList?.filter((workspace) => workspace.id !== workspaceId);

    data = {
      workspace: undefined,
      workspacesList: remainingWorkspaces,
      workspaceMembers: undefined,
    };

    // If the user has more than one project, load the first one after deleting the current one
    const workspaceToLoad = remainingWorkspaces && remainingWorkspaces[0];
    if (workspaceToLoad) {
      dispatch(getWorkspaceById({ workspaceId: workspaceToLoad.id }));
      onSuccess && onSuccess(workspaceToLoad.name);
    }
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Fetch Workspace Usage Dashboard
 * @example await/void dispatch(getWorkspaceUsageDashboard({ workspaceId }));
 */
export const getWorkspaceUsageDashboard = createAsyncThunk<
  { data: StateProps["data"]; error: StateProps["error"] },
  { workspaceId: string },
  { state: RootState }
>("workspaces/get-usage-dashboard", async ({ workspaceId }, { getState }) => {
  let data: StateProps["data"] = getState().workspaces.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<
    z.ZodType<{ status: 200; body: WorkspaceUsageDashboard | null }>
  >({
    schema: z.object({
      status: z.literal(200),
      body: WorkspaceUsageDashboardSchema.nullable(),
    }),
    skipParsing: false,
    method: "GET",
    path: `/workspaces/${workspaceId}/usage-dashboard`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      workspaceUsageDashboard: result.response.body || undefined,
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Set Workspace Mode
 * @example dispatch(setWorkspaceMode(workspaceMode));
 */
export const setWorkspaceMode = createAction<WorkspaceMode>("workspaces/set-mode");

/**
 * Reset Workspace
 * @example dispatch(resetWorkspace());
 */
export const resetWorkspace = createAction("workspaces/reset");

export * from "./workspace-invites";
export * from "./workspace-members";
