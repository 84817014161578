import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store";
import { initialState } from "./initial-state";
import { StateProps, StripePlan } from "./types";
import Stripe from "stripe";
import { EnvironmentVariables } from "logic/internals/runtime/environment-variables";
import { handleLegacyPlans } from "./utils/handle-legacy-plan";
import { handleNewPlans } from "./utils/hanlde-new-plans";

/**
 * Get Plans
 * @param currentSubscription - The current subscription ID (optional)
 * @example dispatch(getPlans({ currentSubscription: 'sub_123' }));
 */
export const getPlans = createAsyncThunk<
  Partial<StateProps>,
  {
    currentSubscription?: string;
  },
  { state: RootState }
>("plans/fetch", async ({ currentSubscription }, { getState }) => {
  let data: StateProps["data"] = getState().plans.data || initialState.data;
  const error: StateProps["error"] = initialState.error;

  const stripe = new Stripe(EnvironmentVariables.STRIPE_KEY, {});

  const prices = await stripe.prices.list({
    active: true,
    limit: 100,
  });

  const products = await stripe.products.list({
    active: true,
    limit: 100,
  });

  const plans: StripePlan[] = products.data.map((product) => {
    const productPrices = prices.data.filter((price) => price.product === product.id);
    return {
      ...product,
      prices: productPrices,
      default_price: typeof product.default_price === "string" ? product.default_price : null,
    } as StripePlan;
  });

  const newPlans = handleNewPlans(plans);

  const legacyPlan = handleLegacyPlans(plans, currentSubscription);

  data = {
    plans: {
      legacyPlan,
      newPlans,
    },
  };

  return {
    data,
    error,
  };
});

/**
 * Reset Plans
 * @example dispatch(resetPlans());
 */
export const resetPlans = createAction("plans/reset");
