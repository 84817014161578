import { createAction } from "@reduxjs/toolkit";
import { Problem } from "../types";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";

/**
 * Update problems as side effect of project update
 * Example of an async action / thunk
 * @example await/void dispatch(updateProblemsProjectSideEffect({ problems, error }));
 */
export const updateProblemsProjectSideEffect = createAction<{
  problems?: Problem[];
  error?: TransportFailure;
}>("problems/update-problems-project-side-effect");
