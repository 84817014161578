// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps, UserInterviewState } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import {
  connectUserInterviewWS,
  disconnectUserInterviewsWS,
  generateUserInterviews,
  regenerateUserInterview,
  getUserInterviewAfterStreaming,
  streamUserInterviews,
  giveUserInterviewFeedback,
  addUserInterviewAnnotation,
  deleteUserInterviewAnnotation,
  resetUserInterviews,
} from "./actions";
import { processUserInterviewMessage } from "./utils/process-user-interview-message";
import { updateUserInterviewsSideEffect } from "../study/actions/side-effects";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder.addCase(resetUserInterviews, () => initialState);
  builder.addCase(updateUserInterviewsSideEffect, (state, action) => ({
    ...state,
    data: action.payload.userInterviews
      ? action.payload.userInterviews.reduce<{
          [key: string]: UserInterviewState;
        }>((acc, userInterview) => {
          acc[userInterview.userInterviewId] = {
            firstAppearedAt: new Date(userInterview.createdAt),
            data: userInterview,
          };
          return acc;
        }, {})
      : undefined,
  }));
  builder.addCase(connectUserInterviewWS, (state) => state);
  builder.addCase(disconnectUserInterviewsWS, (state) => state);
  builder.addCase(streamUserInterviews, (state, action) => {
    const { userInterviewId, messages } = action.payload;

    const initialUserInterview = state.data && state.data[userInterviewId];
    const initialUserInterviewData = state.data && state.data[userInterviewId]?.data;

    if (!initialUserInterview || !initialUserInterviewData) return state;

    let currInterview = Array.isArray(initialUserInterviewData.userInterview)
      ? initialUserInterviewData.userInterview
      : [initialUserInterviewData.userInterview];

    let currentQuestionIndex = !currInterview.length ? 0 : currInterview.length - 1;

    messages.forEach((message) => {
      const { summedUserInterviewText } = processUserInterviewMessage(
        message,
        currInterview,
        currentQuestionIndex
      );
      currInterview = summedUserInterviewText;
      currentQuestionIndex = !summedUserInterviewText.length
        ? 0
        : summedUserInterviewText.length - 1;
    });

    return {
      ...state,
      data: {
        ...state.data,
        [userInterviewId]: {
          ...initialUserInterview,
          data: {
            ...initialUserInterviewData,
            userInterview: currInterview,
          },
        },
      },
    };
  });
  builder
    // Loading start
    .addMatcher(
      isAnyOf(
        generateUserInterviews.pending,
        regenerateUserInterview.pending,
        getUserInterviewAfterStreaming.pending,
        giveUserInterviewFeedback.pending
      ),
      (state) => ({
        ...state,
        loading: state.loading + 1,
      })
    )
    // Get + Add + Duplicate + Update + Delete Problems fulfilled
    .addMatcher(
      isAnyOf(
        generateUserInterviews.fulfilled,
        regenerateUserInterview.fulfilled,
        giveUserInterviewFeedback.fulfilled
      ),
      (state, action) => ({
        ...state,
        ...action.payload,
      })
    )
    // Get + Add + Duplicate + Update + Delete Problems fulfilled
    .addMatcher(
      isAnyOf(
        getUserInterviewAfterStreaming.fulfilled,
        deleteUserInterviewAnnotation.fulfilled,
        addUserInterviewAnnotation.fulfilled
      ),
      (state, action) => ({
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(
        generateUserInterviews.fulfilled,
        generateUserInterviews.rejected,
        regenerateUserInterview.fulfilled,
        regenerateUserInterview.rejected,
        getUserInterviewAfterStreaming.fulfilled,
        getUserInterviewAfterStreaming.rejected,
        giveUserInterviewFeedback.fulfilled,
        giveUserInterviewFeedback.rejected
      ),
      (state) => ({
        ...state,
        loading: state.loading - 1,
      })
    );
});
