import { createAction } from "@reduxjs/toolkit";
import { Solution } from "../types";
import { TransportFailure } from "logic/internals/transports/transported-data/transport-failures";

/**
 * Update solutions as side effect of project update
 * @example await/void dispatch(updateSolutionsProjectSideEffect({ solutions, error }));
 */
export const updateSolutionsProjectSideEffect = createAction<{
  solutions?: Solution[];
  error?: TransportFailure;
}>("solutions/update-solutions-project-side-effect");
