// Redux
import { createReducer, isAnyOf } from "@reduxjs/toolkit";
// Types
import { StateProps } from "./types";
// State
import { initialState } from "./initial-state";
// Actions
import {
  addProject,
  deleteProject,
  getProjectById,
  updateProjectDescription,
  getProjectMembers,
  addProjectMember,
  removeProjectMember,
  inviteProjectMember,
  removeProjectInvite,
  grantProjectMemberAdminStatus,
  revokeProjectMemberAdminStatus,
} from "./actions";
import { updateProjectWorkspaceSideEffect } from "./actions/side-effetcs";

export const reducer = createReducer<StateProps>(initialState, (builder) => {
  builder.addCase(updateProjectWorkspaceSideEffect, (state, action) => ({
    ...state,
    error: action.payload.error,
    data: {
      ...state.data,
      project: action.payload.project,
      projectsList: action.payload.projectsList,
    },
  }));
  builder
    // Loading start
    .addMatcher(
      isAnyOf(
        getProjectById.pending,
        updateProjectDescription.pending,
        addProject.pending,
        deleteProject.pending,
        getProjectMembers.pending,
        addProjectMember.pending,
        removeProjectMember.pending,
        inviteProjectMember.pending,
        removeProjectInvite.pending,
        grantProjectMemberAdminStatus.pending,
        revokeProjectMemberAdminStatus.pending
      ),
      (state) => ({
        ...state,
        loading: state.loading + 1,
      })
    )
    // Actions that return partial data and errors
    .addMatcher(
      isAnyOf(
        getProjectById.fulfilled,
        updateProjectDescription.fulfilled,
        addProject.fulfilled,
        deleteProject.fulfilled,
        getProjectMembers.fulfilled,
        addProjectMember.fulfilled,
        removeProjectMember.fulfilled,
        removeProjectInvite.fulfilled,
        grantProjectMemberAdminStatus.fulfilled,
        revokeProjectMemberAdminStatus.fulfilled
      ),
      (state, action) => ({
        ...state,
        error: action.payload.error,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      })
    )
    // Loading end
    .addMatcher(
      isAnyOf(
        getProjectById.fulfilled,
        getProjectById.rejected,
        updateProjectDescription.fulfilled,
        updateProjectDescription.rejected,
        addProject.fulfilled,
        addProject.rejected,
        deleteProject.fulfilled,
        deleteProject.rejected,
        getProjectMembers.fulfilled,
        getProjectMembers.rejected,
        addProjectMember.fulfilled,
        addProjectMember.rejected,
        removeProjectMember.fulfilled,
        removeProjectMember.rejected,
        inviteProjectMember.fulfilled,
        inviteProjectMember.rejected,
        removeProjectInvite.fulfilled,
        removeProjectInvite.rejected,
        grantProjectMemberAdminStatus.fulfilled,
        grantProjectMemberAdminStatus.rejected,
        revokeProjectMemberAdminStatus.fulfilled,
        revokeProjectMemberAdminStatus.rejected
      ),
      (state) => ({
        ...state,
        loading: state.loading - 1,
      })
    );
});
