// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState } from "store";
import { Solution, StateProps } from "../types";
// Schemas
import { SolutionSchema } from "../schemas";
// Initial State
import { initialState } from "../initial-state";
// Main Api
// Zod
import { getMainApi } from "store/utils/main-api";
import { z } from "zod";

/**
 * Add Solution
 * @example await/void dispatch(addSolution({ projectId, description }));
 */
export const addSolution = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    description: string;
  },
  { state: RootState }
>("solutions/add", async ({ projectId, description }, { getState }) => {
  let data: StateProps["data"] = getState().solutions.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Solution }>>({
    schema: z.object({
      status: z.literal(200),
      body: SolutionSchema,
    }),
    skipParsing: false,
    method: "POST",
    path: `/solutions`,
    body: {
      projectId,
      description,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      solutions: [result.response.body, ...(data.solutions || [])],
      selectedSolutions: [...(data.selectedSolutions || []), result.response.body],
      selectedSolutionId: [result.response.body.id],
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Update Solution
 * @example await/void dispatch(updateSolution({ projectId, solutionId, description }));
 */
export const updateSolution = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    solutionId: string;
    description: string;
  },
  { state: RootState }
>("solutions/update", async ({ projectId, solutionId, description }, { getState }) => {
  let data: StateProps["data"] = getState().solutions.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Solution }>>({
    schema: z.object({
      status: z.literal(200),
      body: SolutionSchema,
    }),
    skipParsing: false,
    method: "PUT",
    path: `/solutions/${solutionId}`,
    body: {
      projectId,
      description,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      selectedSolutions: data.selectedSolutions?.map((solution) =>
        solution.id === solutionId ? result.response.body : solution
      ),
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Reset Selected Solution
 * @example dispatch(resetSelectedSolution());
 */
export const resetSelectedSolution = createAction("solutions/reset-selected");

/**
 * Reset Solutions
 * @example dispatch(resetSolutions());
 */
export const resetSolutions = createAction("solutions/reset");
