// Redux
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
// Typings
import { RootState } from "store";
import { Audience, StateProps } from "../types";
// Schemas
import { AudiencesSchema } from "../schemas";
// Initial State
import { initialState } from "../initial-state";
// Main Api
// Zod
import { getMainApi } from "store/utils/main-api";
import { z } from "zod";

/**
 * Add Audience
 * @example await/void dispatch(addAudience({ projectId, description, onSuccess }));}));
 */
export const addAudience = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    description: string;
    onSuccess?: (audienceId: string) => void;
  },
  { state: RootState }
>("audiences/add", async ({ projectId, description, onSuccess }, { getState }) => {
  let data: StateProps["data"] = getState().audiences.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Audience }>>({
    schema: z.object({
      status: z.literal(200),
      body: AudiencesSchema,
    }),
    skipParsing: false,
    method: "POST",
    path: `/syntheticUsers`,
    body: {
      projectId,
      description,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      audiences: [result.response.body, ...(data.audiences || [])],
      selectedAudiences: [result.response.body],
      selectedAudiencesIds: [result.response.body.id],
    };

    onSuccess && onSuccess(result.response.body.id);
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Update Audience
 * @example await/void dispatch(updateAudience({ projectId, audienceId, description }));
 */
export const updateAudience = createAsyncThunk<
  Partial<StateProps>,
  {
    projectId: string;
    audienceId: string;
    description: string;
  },
  { state: RootState }
>("audiences/update", async ({ projectId, audienceId, description }, { getState }) => {
  let data: StateProps["data"] = getState().audiences.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: Audience }>>({
    schema: z.object({
      status: z.literal(200),
      body: AudiencesSchema,
    }),
    skipParsing: false,
    method: "PUT",
    path: `/syntheticUsers/${audienceId}`,
    body: {
      projectId,
      description,
    },
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      ...data,
      audiences: data.audiences?.map((audience) =>
        audience.id === audienceId ? result.response.body : audience
      ),
      selectedAudiences: [result.response.body],
      selectedAudiencesIds: [result.response.body.id],
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Reset Selected Audiences
 * @example dispatch(resetSelectedProblems());
 */
export const resetSelectedAudiences = createAction("audiences/reset-selected");

/**
 * Reset Audiences
 * @example dispatch(resetAudiences());
 */
export const resetAudiences = createAction("audiences/reset");
