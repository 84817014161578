import { RootState } from "store";
import { StateProps, WorkspaceMember } from "../types";
import { z } from "zod";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMainApi } from "store/utils/main-api";
import { WorkspaceMemberSchema } from "../schemas";
import { initialState } from "../initial-state";

/**
 * Get Workspace Members
 * @example await/void dispatch(getWorkspaceMembers({ workspaceId }));
 */
export const getWorkspaceMembers = createAsyncThunk<
  { data: StateProps["data"]; error: StateProps["error"] },
  { workspaceId: string },
  { state: RootState }
>("workspaces/get-members", async ({ workspaceId }, { getState }) => {
  let data: StateProps["data"] = getState().workspaces.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch<z.ZodType<{ status: 200; body: WorkspaceMember[] }>>({
    schema: z.object({
      status: z.literal(200),
      body: z.array(WorkspaceMemberSchema),
    }),
    method: "GET",
    path: `/workspaces/workspaces/${workspaceId}/users`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      workspaceMembers: result.response.body,
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Remove Workspace Member
 * @example await/void dispatch(removeWorkspaceMember({ workspaceId, userId, onSuccess }));
 */
export const removeWorkspaceMember = createAsyncThunk<
  {
    data: StateProps["data"];
    error: StateProps["error"];
  },
  { workspaceId: string; userId: string; onSuccess: () => void },
  { state: RootState }
>("workspaces/remove-member", async ({ workspaceId, userId, onSuccess }, { getState }) => {
  let data: StateProps["data"] = getState().workspaces.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.union([z.literal(200), z.literal(401), z.literal(404), z.literal(500)]),
      body: z.array(WorkspaceMemberSchema),
    }),
    method: "DELETE",
    path: `/workspaces/workspaces/${workspaceId}/users/${userId}`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      workspaceMembers: result.response.body,
    };

    onSuccess();
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Grant Workspace Member Owner Status
 * @example await/void dispatch(grantWorkspaceMemberOwnerStatus({ workspaceId, userId }));
 */
export const grantWorkspaceMemberOwnerStatus = createAsyncThunk<
  {
    data: StateProps["data"];
    error: StateProps["error"];
  },
  { workspaceId: string; userId: string },
  { state: RootState }
>("workspaces/grant-owner-status", async ({ workspaceId, userId }, { getState }) => {
  let data: StateProps["data"] = getState().workspaces.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.union([z.literal(200), z.literal(401), z.literal(404), z.literal(500)]),
      body: z.array(WorkspaceMemberSchema),
    }),
    method: "POST",
    path: `/workspaces/workspaces/${workspaceId}/users/${userId}/owner`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      workspaceMembers: result.response.body,
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});

/**
 * Revoke Workspace Member Owner Status
 * @example await/void dispatch(revokeWorkspaceMemberOwnerStatus({ workspaceId, userId }));
 */
export const revokeWorkspaceMemberOwnerStatus = createAsyncThunk<
  {
    data: StateProps["data"];
    error: StateProps["error"];
  },
  { workspaceId: string; userId: string },
  { state: RootState }
>("workspaces/revoke-owner-status", async ({ workspaceId, userId }, { getState }) => {
  let data: StateProps["data"] = getState().workspaces.data || initialState.data;
  let error: StateProps["error"] = initialState.error;

  const mainApi = getMainApi();

  const result = await mainApi.fetch({
    schema: z.object({
      status: z.union([z.literal(200), z.literal(401), z.literal(404), z.literal(500)]),
      body: z.array(WorkspaceMemberSchema),
    }),
    method: "POST",
    path: `/workspaces/workspaces/${workspaceId}/users/${userId}/revoke-owner`,
  });

  if (result.failure) {
    error = result.failure;
  } else {
    data = {
      workspaceMembers: result.response.body,
    };
  }

  // The value we return becomes the `fulfilled` action payload
  return {
    data,
    error,
  };
});
